(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Search
      $('.search-toggle').click(function(e){
        e.preventDefault();
        $('#search-popup').addClass('active');
        $('#search-input').focus();
      });
      $('.close-search').click(function(){
        $('#search-popup').removeClass('active');
      });

      // Cookied Popup
      $('.close-cookie-popup').on('click', function(){
        $('.cookied-popup-wrapper').css('display', 'none');
        $.cookie('popup', $('.cookied-popup-wrapper').attr('id'), { path: '/' });
      });

      // Status Bar
      $('.status-bar').on('click', function(){
        $('.status-bar').css('display', 'none');
        $.cookie('status', $('.status-bar').attr('id'), { path: '/' });
      });
      setTimeout(function(){
        $('.status-bar').addClass('loaded');
      }, 800);

      // Pagination
      $('#pagination').on('click', 'a', function(e){
        e.preventDefault();
        $(this).remove();
        $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('#ajax-posts').append($('#ajax-loader').html());
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Anchor
      $('a').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href && $href !== '#') {
          if ($href.match('^#') && !$(this).hasClass('toggle')) {
            e.preventDefault();
            if ($($href).length) {
              $('html, body').stop().animate({
                  scrollTop: $($href).offset().top
              });
            }
          }
        }
      });

      // Accordions
      $('.the-accordion .toggle').on('click', function(){
        var $this = $(this).parent('.the-accordion');
        $this.toggleClass('active');
        if ($this.hasClass('active')) {
          setTimeout(function(){
            $this.addClass('shown');
          }, 100 );
        } else {
          $this.removeClass('shown');
        }
      });

      // Campaigns
      $('.progress-bar .value').each(function(){
        $(this).css('width', $(this).data('value') + '%');
      });

      // Form Labels
      $('input').on('focus', function(){
        $(this).parent('*').addClass('filled');
      });
      $('input').on('blur', function(){
        if ($(this).val() !== '') {
          $(this).parent('*').addClass('filled');
        } else {
          $(this).parent('*').removeClass('filled');
        }
      });

      // The Quiz
      var results = '',
          $quiz = $('#the-quiz');
      $quiz.find('.btn').click(function(e){
        e.preventDefault();
        $btn = $(this);
        if ($btn.hasClass('next') && $btn.attr('data-result') === 'error') {
          $btn.parents('.btn-wrapper').prev('.quiz-toggle-wrapper').addClass('error');
        } else {
          $quiz.attr('data-step', $btn.attr('id'));
          if ($btn.attr('id') === 'q2') {
            $quiz.attr('data-q1', $btn.attr('data-result'));
            if ($btn.attr('data-result') === 'd') {
              $quiz.attr('data-step', 'r');
              $quiz.attr('data-result', $quiz.attr('data-q1') + $quiz.attr('data-q2') + $quiz.attr('data-q3'));
              $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
            } else if ($btn.attr('data-result') === 'a') {
              $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
            } else if ($btn.attr('data-result') === 'b') {
              $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
            } else if ($btn.attr('data-result') === 'c') {
              $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '1'); $quiz.attr('data-case14', '1'); $quiz.attr('data-case15', '1'); $quiz.attr('data-case16', '1'); $quiz.attr('data-case17', '1'); $quiz.attr('data-case18', '1'); $quiz.attr('data-case19', '1'); $quiz.attr('data-case20', '1');
            }
          }
          if ($btn.attr('id') === 'q3') {
            $quiz.attr('data-q2', $btn.attr('data-result'));
            if ($quiz.attr('data-q1') === 'a') {
              if ($btn.attr('data-result') === 'a') {
                $quiz.attr('data-step', 'q4');
                $quiz.attr('data-result', $quiz.attr('data-q1') + $quiz.attr('data-q2') + $quiz.attr('data-q3'));
                $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'b') {
                $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'c') {
                $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              }
            }
            if ($quiz.attr('data-q1') === 'b') {
              if ($btn.attr('data-result') === 'a') {
                $quiz.attr('data-step', 'q4');
                $quiz.attr('data-result', $quiz.attr('data-q1') + $quiz.attr('data-q2') + $quiz.attr('data-q3'));
                $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'b') {
                $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'c') {
                $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              }
            }
            if ($quiz.attr('data-q1') === 'c') {
              if ($btn.attr('data-result') === 'a') {
                $quiz.attr('data-step', 'q4');
                $quiz.attr('data-result', $quiz.attr('data-q1') + $quiz.attr('data-q2') + $quiz.attr('data-q3'));
                $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'b') {
                $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '2'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
              } else if ($btn.attr('data-result') === 'c') {
                $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '1'); $quiz.attr('data-case14', '1'); $quiz.attr('data-case15', '1'); $quiz.attr('data-case16', '1'); $quiz.attr('data-case17', '1'); $quiz.attr('data-case18', '1'); $quiz.attr('data-case19', '1'); $quiz.attr('data-case20', '1');
              }
            }
          }
          if ($btn.attr('id') === 'q4') {
            $quiz.attr('data-q3', $btn.attr('data-result'));
            if ($quiz.attr('data-q1') === 'a') {
              if ($quiz.attr('data-q2') === 'b') {
                if ($btn.attr('data-result') === '1' || $btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2' || $btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '12' || $btn.attr('data-result') === '23') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              } else if ($quiz.attr('data-q2') === 'c') {
                if ($btn.attr('data-result') === '1' || $btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2' || $btn.attr('data-result') === '12' || $btn.attr('data-result') === '23' || $btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              }
            } else if ($quiz.attr('data-q1') === 'b') {
              if ($quiz.attr('data-q2') === 'b') {
                if ($btn.attr('data-result') === '1' || $btn.attr('data-result') === '12') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '13' || $btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '23') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              } else if ($quiz.attr('data-q2') === 'c') {
                if ($btn.attr('data-result') === '1') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '12') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '13' || $btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '23') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              }
            } else if ($quiz.attr('data-q1') === 'c') {
              if ($quiz.attr('data-q2') === 'b') {
                if ($btn.attr('data-result') === '1') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '12') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '13') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '23') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '0'); $quiz.attr('data-case11', '0'); $quiz.attr('data-case12', '0'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              } else if ($quiz.attr('data-q2') === 'c') {
                if ($btn.attr('data-result') === '1') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '2') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '3') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '0'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '12') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '0'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '0'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '0'); $quiz.attr('data-case9', '0'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '13') {
                  $quiz.attr('data-case1', '0'); $quiz.attr('data-case2', '0'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '23') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '0'); $quiz.attr('data-case7', '0'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                } else if ($btn.attr('data-result') === '123') {
                  $quiz.attr('data-case1', '1'); $quiz.attr('data-case2', '1'); $quiz.attr('data-case3', '1'); $quiz.attr('data-case4', '1'); $quiz.attr('data-case5', '1'); $quiz.attr('data-case6', '1'); $quiz.attr('data-case7', '1'); $quiz.attr('data-case8', '1'); $quiz.attr('data-case9', '1'); $quiz.attr('data-case10', '1'); $quiz.attr('data-case11', '1'); $quiz.attr('data-case12', '1'); $quiz.attr('data-case13', '0'); $quiz.attr('data-case14', '0'); $quiz.attr('data-case15', '0'); $quiz.attr('data-case16', '0'); $quiz.attr('data-case17', '0'); $quiz.attr('data-case18', '0'); $quiz.attr('data-case19', '0'); $quiz.attr('data-case20', '0');
                }
              }
            }
          }
          if ($btn.attr('id') === 'r') {
            if ($btn.attr('data-result').includes('1')) {
              $quiz.attr('data-case13', '1');
            }
            if ($btn.attr('data-result').includes('2')) {
              $quiz.attr('data-case14', '1');
            }
            if ($btn.attr('data-result').includes('3')) {
              $quiz.attr('data-case15', '1');
            }
            if ($btn.attr('data-result').includes('4')) {
              $quiz.attr('data-case16', '1');
            }
            if ($btn.attr('data-result').includes('5')) {
              $quiz.attr('data-case17', '1');
            }
            if ($btn.attr('data-result').includes('6')) {
              $quiz.attr('data-case18', '1');
            }
            if ($btn.attr('data-result').includes('7')) {
              $quiz.attr('data-case19', '1');
            }
            if ($btn.attr('data-result').includes('8')) {
              $quiz.attr('data-case20', '1');
            }       
            $quiz.attr('data-q4', $btn.attr('data-result'));
            $quiz.attr('data-result', $quiz.attr('data-q1') + $quiz.attr('data-q2') + $quiz.attr('data-q3'));
          }
        }
      });
      $('.quiz-toggle').click(function(){
        $toggle = $(this);
        $('.quiz-toggle-wrapper').removeClass('error');
        if ($toggle.hasClass('radio')) {
          $toggle.siblings('.radio').removeClass('selected');
          $toggle.addClass('selected');
          $toggle.parents('.quiz-toggle-wrapper').next('.btn-wrapper').children('.btn.next').attr('data-result', $toggle.attr('data-result'));
        }
        if ($toggle.hasClass('check')) {
          $toggle.toggleClass('selected');
          var $next = $toggle.parents('.quiz-toggle-wrapper').next('.btn-wrapper').children('.btn.next');
          $next.attr('data-result', '');
          $toggle.parents('.quiz-toggle-wrapper').children('.check.selected').each(function(){
            var $result = $(this).attr('data-result');
            $next.attr('data-result', $next.attr('data-result') + $result);
          });
        }
      });
      // End The Quiz

      // Video popups
      $('a').on('click', function(e){
        var $target = $(this).attr('href');
        if ($target.toLowerCase().indexOf("vimeo") >= 0) {
          e.preventDefault();
          var $n = $target.lastIndexOf('/');
          var $vid = $target.substring($n + 1);
          $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("youtu") >= 0) {
          e.preventDefault();
          var $y = $target.lastIndexOf('=');
          var $yid = $target.substring($y + 1);
          $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
      });
      $('.close-popup').on('click', function(){
        $('.video-popup-wrapper').removeClass('active');
        $('.popup-content').html('');
        $('body, html').removeClass('body-noscroll');
      });

      // Mobile Nav
      var scrolltop = 0;
      $('#mobile-nav-toggle').on('click', function(){
        if ($('#mobile-navigation-wrapper').hasClass('active')) {
          $('html, body').stop().animate({
              scrollTop: scrolltop
          }, 1);
        } else {
          scrolltop = $(window).scrollTop();
        }
        var $navheight = $('#mobile-navigation').height() + 170;
        $('body, html').toggleClass('nav-locked');
        $('#mobile-navigation-wrapper').toggleClass('active');
        $(this).toggleClass('active');
      });

      // Resize for Match-Height and Slick-Slider glitch fix
      $(window).bind('load', function(){
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
      });

      // Sticky Header
      stickyheader = function(){
        if ($(document).scrollTop() > 1) {
          $('.global-header').addClass('sticky');
        } else {
          $('.global-header').removeClass('sticky');
        }
      };

      // Animations
      animations = function(){
        $('.global-hero .background-image').each(function(){
          var $this = $(this);
          if ($this.offset().top < $(window).scrollTop() + $(window).height() - 100) {
             setTimeout(function(){
              $this.addClass('active');
            }, 100);
          }
        });
        // $('.grid_ctas').each(function(){
        //   var $this = $(this);
        //   if ($this.offset().top < $(window).scrollTop() + $(window).height() - 150) {
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:first-child .cta').addClass('reveal');
        //     }, 100);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(2) .cta').addClass('reveal');
        //     }, 400);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(3) .cta').addClass('reveal');
        //     }, 700);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(4) .cta').addClass('reveal');
        //     }, 1000);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(5) .cta').addClass('reveal');
        //     }, 1300);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(6) .cta').addClass('reveal');
        //     }, 1600);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(7) .cta').addClass('reveal');
        //     }, 1900);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(8) .cta').addClass('reveal');
        //     }, 2200);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(9) .cta').addClass('reveal');
        //     }, 2500);
        //     setTimeout(function(){
        //       $this.find('.col-sm-6:nth-child(10) .cta').addClass('reveal');
        //     }, 2800);
        //   }
        // });
        // $('.hero').each(function(){
        //   var $this = $(this);
        //   if ($this.find('.pretitle').length !== 0) {
        //     $this.find('.pretitle').addClass('reveal');
        //     setTimeout(function(){
        //       $this.find('.title').addClass('reveal');
        //     }, 200);
        //     setTimeout(function(){
        //       $this.find('.content-styled').addClass('reveal');
        //     }, 500);
        //     setTimeout(function(){
        //       $this.find('.btn').addClass('reveal');
        //     }, 500);
        //   } else {
        //     $this.find('.title').addClass('reveal');
        //     setTimeout(function(){
        //       $this.find('.content-styled').addClass('reveal');
        //     }, 300);
        //     setTimeout(function(){
        //       $this.find('.btn').addClass('reveal');
        //     }, 300);
        //   }
        // });
      };

      // Load functions
      $(window).bind('load', function(){
        stickyheader();
        animations();
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
      });

      // Scroll functions
      $(window).scroll(function(){
        stickyheader();
        animations();
      });

      // Resize functions
      $(window).bind('resize', function(){
        // Add resize functions here
      });

      // Custom Dropdowns
      $('body, html').click(function(e){
        if (!$(e.target).hasClass('single-dropdown') && !$(e.target).parents('.single-dropdown').length && !$(e.target).hasClass('multiple-dropdown') && !$(e.target).parents('.multiple-dropdown').length) {
          $('.single-dropdown, .multiple-dropdown').removeClass('active');
        }
      });
      $('.single-dropdown, .multiple-dropdown').click(function(){
        $(this).toggleClass('active');
      });
      $('.single-dropdown li').click(function(){
        $(this).siblings('li').removeClass('selected');
        $(this).addClass('selected');
        var $text = $(this).parents('.single-dropdown').find('.text');
        if ($(this).data('value') === 'all') {
          $text.text($text.data('og'));
        } else {
          $text.text($(this).text());
        }
      });
      $('.multiple-dropdown li').click(function(){    
        var $text = $(this).parents('.multiple-dropdown').find('.text');
        if ($(this).data('value') === 'all') {
          $(this).addClass('selected');
          $(this).siblings('li').removeClass('selected');
          $text.text($text.data('og'));
        } else {
          $(this).toggleClass('selected');
          $(this).siblings('.view-all').removeClass('selected');
          var $count = $(this).parents('.multiple-dropdown').find('.selected:not(.view-all)').length;
          if ($count > 0) {
            $text.text($text.data('og') + $text.data('plural') + ' (' + $count + ')');
          } else {
            $text.text($text.data('og'));
          }
        }
      });
      $('.single-dropdown').each(function(){
        var $text = $(this).find('.text');
        if ($(this).find('.selected').data('value') === 'all') {
          $text.text($text.data('og'));
        } else {
          $text.text($(this).find('.selected:not(.view-all)').text());
        }
      });
      $('.multiple-dropdown').each(function(){    
        var $text = $(this).find('.text');
        if ($(this).find('.selected').data('value') === 'all') {
          $text.text($text.data('og'));
        } else {
          var $count = $(this).find('.selected:not(.view-all)').length;
          if ($count > 0) {
            $text.text($text.data('og') + $text.data('plural') + ' (' + $count + ')');
          } else {
            $text.text($text.data('og'));
          }
        }
      });
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page
      $('.home-hero .images .image:first').addClass('active');
      setInterval(function(){
        var $active = $('.home-hero .images .image.active');
        $('.home-hero .images .image').removeClass('active');
        if ($active.next('.image').length) {
          $active.next('.image').addClass('active');
        } else {
          $('.home-hero .images .image:first').addClass('active');
        }
      }, 7000);
    }
  },
  // Resources page
  page_template_template_resource_php: {
    init: function() {
      // JavaScript to be fired on the home page
      function filter(e) {
        if (e) {
          e.preventDefault();
        }
        $('#filter-terms *').remove();
        $('.filters-subnavigation').removeClass('reset-visible');
        $('.view-all').addClass('selected');
        var $query = [],
            $url = '/volunteer/resources/',
            $search = encodeURI($('#search-filter .input').val());
        if ($('.resource-filter .selected:not(.view-all)').length || $search.length) {
          $('.filters-subnavigation').addClass('reset-visible');
          if ($search.length) {
            var $searchparams = 'search=' + $search;
            $query.push( $searchparams );
          }
          if ($('.area-of-interest .selected:not(.view-all)').length) {
            $('.area-of-interest .view-all').removeClass('selected');
            var $interestparams = 'area-of-interest=';
            $.each($('.area-of-interest .selected:not(.view-all)'), function(index, value) {
              if (index > 0) {
                $interestparams += ',';
              }
              $interestparams += $(this).data('value');
              $('#filter-terms').append('<li data-taxonomy="case-type" data-term="' + $(this).data('value') + '">' + $(this).text() + '</li>');
            });
            $query.push( $interestparams );
          }
          if ($('.resource-type .selected:not(.view-all)').length) {
            $('.resource-type .view-all').removeClass('selected');
            var $typeparams = 'resource-type=';
            $.each($('.resource-type .selected:not(.view-all)'), function(index, value) {
              if (index > 0) {
                $typeparams += ',';
              }
              $typeparams += $(this).data('value');
              $('#filter-terms').append('<li data-taxonomy="case-type" data-term="' + $(this).data('value') + '">' + $(this).text() + '</li>');
            });
            $query.push( $typeparams );
          }
          $query = $query.join('&', $query);
          if( $query.length ){
            $url = $url + '?' + $query;
            window.history.pushState({'html':'','pageTitle':'Resource Library'},'', $url);
          }
        }
        if ($url === '/volunteer/resources/') {
          $('.featured-resources-section').fadeIn();
        } else {
          $('.featured-resources-section').fadeOut();
        }
        $('#ajax-posts article').remove();
        $('#pagination a').remove();
        $('#ajax-posts').load($url + ' #ajax-posts>*');
        $('#pagination').load($url + ' #pagination>*');
      }
      $('.resource-filter li').click(function(e){
        filter(e);
      });
      $('#search-filter').submit(function(e){
        filter(e);
      });
      $('#clear-search').click(function(e){
        $('#search-filter .input').val('');
        filter(e);
      });
      $('#reset-filters').click(function(e){
        $('.resource-filter li').removeClass('selected');
        $('.resource-filter .text').each(function(){
          $(this).text($(this).data('og'));
        });
        $('#search-filter .input').val('');
        filter(e);
      });
      $('#filter-terms').click('li', function(e){
        $('.resource-filter li[data-value="' + $(e.target).data('term') + '"]').removeClass('selected');
        filter(e);
        $('.resource-filter').each(function(){    
          var $text = $(this).find('.text');
          var $count = $(this).find('.selected:not(.view-all)').length;
          if ($count > 0) {
            if ($text.data('plural')) {
              $text.text($text.data('og') + $text.data('plural') + ' (' + $count + ')');
            }
          } else {
            $text.text($text.data('og'));
          }
        });
      });
      filter();
    }
  },
  // Cases page
  page_template_template_case_php: {
    init: function() {
      // JavaScript to be fired on the home page
      function filter(e) {
        if (e) {
          e.preventDefault();
        }
        $('#filter-terms *').remove();
        $('.filters-subnavigation').removeClass('reset-visible');
        var $query = [],
            $url = '/case-listings/';
        if ($('.case-filter .selected:not(.view-all)').length) {
          $('.filters-subnavigation').addClass('reset-visible');
          if ($('.case-type .selected').length) {
            var $typeparams = 'case-type=';
            $.each($('.case-type .selected:not(.view-all)'), function(index, value) {
              if (index > 0) {
                $typeparams += ',';
              }
              $typeparams += $(this).data('value');
              $('#filter-terms').append('<li data-taxonomy="case-type" data-term="' + $(this).data('value') + '">' + $(this).text() + '</li>');
            });
            $query.push( $typeparams );
          }
          if ($('.skills-developed .selected:not(.view-all)').length) {
            var $skillsparams = 'skills-developed=';
            $.each($('.skills-developed .selected:not(.view-all)'), function(index, value) {
              if (index > 0) {
                $skillsparams += ',';
              }
              $skillsparams += $(this).data('value');
              $('#filter-terms').append('<li data-taxonomy="skills-developed" data-term="' + $(this).data('value') + '">' + $(this).text() + '</li>');
            });
            $query.push( $skillsparams );
          }
          $query = $query.join('&', $query);
          if( $query.length ){
            $url = $url + '?' + $query;
            window.history.pushState({'html':'','pageTitle':'Case Listings'},'', $url);
          }
        }
        $('#ajax-posts article').remove();
        $('#pagination a').remove();
        $('#ajax-posts').load($url + ' #ajax-posts>*');
        $('#pagination').load($url + ' #pagination>*');
      }
      $('.case-filter li').click(function(e){
        filter(e);
      });
      $('#reset-filters').click(function(e){
        $('.filters-subnavigation').removeClass('reset-visible');
        $('.case-filter li').removeClass('selected');
        $('.case-filter .text').each(function(){
          $(this).text($(this).data('og'));
        });
        filter(e);
      });
      $('#filter-terms').click('li', function(e){
        $('.case-filter li[data-value="' + $(e.target).data('term') + '"]').removeClass('selected');
        filter(e);
        $('.case-filter').each(function(){    
          var $text = $(this).find('.text');
          var $count = $(this).find('.selected:not(.view-all)').length;
          if ($count > 0) {
            $text.text($text.data('og') + $text.data('plural') + ' (' + $count + ')');
          } else {
            $text.text($text.data('og'));
          }
        });
      });
      filter();
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.